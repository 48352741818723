import React, { useState, useEffect, ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";
import PheonixTextField from "../Components/PheonixTextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import themestyle from "../theme";
import SnackBar from '../Components/PheonixSnackBar';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import baseUrl from "../Api";
import axios from "axios";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../constant";
import IconButton from '@mui/material/IconButton';
import logo from "../assets/Dlogo.svg";
import { ContactsOutlined } from "@mui/icons-material";
import { useSession } from "../context/sessioncontext";

const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});

const Login: React.FC = () => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { login } = useSession();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [pasErrorMessage, setPasErrorMessage] = useState('');
  const [succMessage, setSuccMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('error');
  useEffect(() => {
    localStorage.setItem('isLoggedIn', 'false');
  }, []);

  useEffect(() => {
    const emailValid = isValidEmail(email);
    const passwordValid = isValidPassword(password);
    if (!emailValid || !passwordValid) {
      setIsLoginButtonDisabled(false);
    } else {
      setIsLoginButtonDisabled(true);
    }
  }, [email, password]);

  const isValidPassword = (password: string): boolean => {
    const minLength = 8;
    return password.length >= minLength;
  };
  function isValidEmail(email: string) {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage('');
    }
  };
  const handlePasswordBlur = () => {
    const minLength = 8;
    const passlength = password.length >= minLength;
    if (password.length === 0) {
      setPasErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    }
    else if (!passlength) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
    } else {
      setPasErrorMessage('');
    }
  }
  const handlePasswordFocus = () => {
    setPasErrorMessage('');
    setErrorMessage('');
  };
  const handleKeyPress = (event: any) => {
    if (event.key === TEXT_MESSAGES.ENTER) {
      handleLogin();
    }
  };
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleClose = () => {
    setModalOpen(false);
    navigate('/dashboard');
  };
  const handleLogin = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
      return;
    }
    if (!isValidPassword(password)) {
      setPasErrorMessage(ERROR_MESSAGES.PWD_LENGTH);
      return;
    }
    const query = `
    mutation LoginUser($email : String!,  $password: String!){
        loginUser(email : $email,  password : $password){
        success
        message 
        token
        username
        }
    }
    `;
    const variables = { email, password };
    try {
      const response = await axios.post(baseUrl, {
        query,
        variables,
      });

      const { success, message, token, username,refreshToken } = response.data.data.loginUser;

      if (success) {
        setSuccMessage(TEXT_MESSAGES.Log_SUCCESS);
        setSnackbarSeverity('success');
        localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        login();
        navigate("/dashboard", { replace: true });

        setemail("");
        setpassword("");
      } else {
        setSnackbarSeverity('error');
        const error = message.toLowerCase();
        if (error.includes('email')) {
          setErrorMessage(message);
        } else if (error.includes('password')) {
          setPasErrorMessage(message);
        } else {
          setErrorMessage(message);
        }
      }
    } catch (error) {
      setSnackbarSeverity('error');
      console.error(ERROR_MESSAGES.ERROR, error);
    }
  };


  const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0",
        padding: "0",
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: themestyle.colors.login_backgroundcolor
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="16px"
        boxShadow={3}
        component="form"
        onKeyPress={handleKeyPress}
        sx={{
          height: {
            xs: "60vh",
            sm: "70vh",
            md: "80vh",
            lg: "550px",
            xl: "60vh",
          },
          width: {
            xs: "80vw",
            sm: "50vw",
            md: "40vw",
            lg: "400px",
            xl: "25vw",
          },
          backgroundColor: "#FFFFFF"
        }}
      >
        <Stack spacing={2} direction="column">
          <Item>
            <img src={logo} style={{ height: "72px", width: "130px" }} />
          </Item>
          <Item>
            <Typography
              variant="h6"
              style={{
                color: "1B2435",
                fontFamily: themestyle.colors.fontfamily,
                fontSize: "24px",
              }}
            >
              Testing Login 2
            </Typography>
          </Item>
          <Item>
            <PheonixTextField
              id="outlined-error"
              type="email"
              value={email}
              placeholder="Email"
              label="Email"
              variant="outlined"
              onChange={handleemailChange}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              error={Boolean(errorMessage)}
              helperText={errorMessage || ''}
              style={{
                width: isMobileSmall ? "250px" : "350px",
              }}

            />
          </Item>
          <Item>
            <PheonixTextField
              id="outlined-error"
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="Password"
              label="Password"
              variant="outlined"
              onChange={handlepasswordChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              error={Boolean(pasErrorMessage)}
              helperText={pasErrorMessage || ''}
              style={{
                width: isMobileSmall ? "250px" : "350px",
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer", marginTop: "0px" }}
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              }
              disabled={Boolean(errorMessage)}
            />
          </Item>
          <Item>
            <PheonixButton
              label="LOGIN"
              style={{
                width: isMobileSmall ? "250px" : "350px",
              }}
              onClick={handleLogin}
              disabled={!isLoginButtonDisabled}
            />
          </Item>
        </Stack>
        <SnackBar
          open={modalOpen}
          onClose={handleClose}
          message={succMessage}
          vertical="top"
          horizontal="center"
          customAction={customCloseIcon}
          severity={snackbarSeverity}
        />

      </Box>
    </div>
  );
};

export default Login;